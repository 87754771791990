<template>
    <div style="height: 100%;">
        <div class="addressBookDetails">
            <div class="addressBookDetails_top">
                <div class="addressBookDetails_top_name">{{AddressBookDetailsArr.name}} </div>
                <div>/商会职务：{{AddressBookDetailsArr.job}}</div>
            </div>

            <div class="addressBookDetails_centre">
                <div>
                    单位：{{AddressBookDetailsArr.companyName}}
                </div>
                <div>
                    联系电话：{{AddressBookDetailsArr.phone}}
                </div>
                <div>
                    统一社会信用代码：{{AddressBookDetailsArr.creditCode}}
                </div>
                <div>
                    居住地：{{AddressBookDetailsArr.address}}
                </div>
            </div>

            <div class="addressBookDetails_bottom">
                <div class="addressBookDetails_bottom_top">
                    <div class="addressBookDetails_bottom_top_left"></div>
                    <div>
                        <div>经营范围</div>
                        <div>Business Scope</div>
                    </div>
                </div>
                <div class="addressBookDetails_bottom_bottom">
                    {{AddressBookDetailsArr.businessScope}}
                </div>
            </div>

        </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, reactive } from "@/plugin/importCommonUse";
  import { computed, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import moduleHead from "@/components/moduleHead/index.vue";
  export default defineComponent({
    components: {
      moduleHead,
    },
    setup() {
      let route = useRoute();
      let router = useRouter();
      let AddressBookDetailsArr = ref([])
      let init = (() => {
        AddressBookDetailsArr.value = JSON.parse(localStorage.getItem("AddressBookDetails"))
      });
      init()
      return {
        AddressBookDetailsArr
      };
    },
  });
  </script>
  <style>
  .addressBookDetails{
    /* background-image: url('../../../assets/index/txl_xiangqing.jpg'); */
    background: url('../../../assets/index/txl_xiangqing.jpg') no-repeat;
    background-color: #B44238;
    background-size:100%;
    padding: 10px;
    color: #ffffff;
    letter-spacing: 2px;
    height: 100%;
  }
  .addressBookDetails_top{
    display: flex;
    align-items: flex-end;
    margin-top: 140px;
    padding: 5px 0;
    border-bottom: 1px solid #ffffff;
  }
  .addressBookDetails_top_name{
    font-size: 24px;
    
  }
  .addressBookDetails_centre{
    padding: 15px 0;
    text-align: left;
  }
  .addressBookDetails_centre div{
    margin-bottom: 12px;
  }
  .addressBookDetails_bottom{
    text-align: left;
    margin-bottom: 40px;
  }
  .addressBookDetails_bottom_top{
    margin-bottom: 13px;
    display: flex;
    align-items: center
  }
  .addressBookDetails_bottom_top_left{
    width: 3px;
    height: 34px;
    background: #ffffff;
    margin-right: 10px;
}
.addressBookDetails_bottom_bottom{
    font-size: 14px;
}
  </style>
  